<template>
  <b-container class="customer-wrapper mt-5">
    <button class="btn btn-link" @click="goHome()">&lt; Voltar</button>
    <b-card class="shadow-sm" id="section-to-print" no-body>
      <div class="p-3 bg-gray-100 border-bottom d-flex align-items-center">
        <UserInitials
          class="text-white flex-shrink-0"
          v-bind:userName="customer.name"
          size="lg"
        />
        <div class="ml-3 border-left pl-3">
          <h6 v-if="isEditing" class="text-uppercase text-gray-600 small">Nome do Cliente</h6>
          <div v-if="isEditing">
            <input type="text" class="form-control form-control-sm mb-1" v-model="customer.name" v-on:blur="inputValidation('name')" />
            <ValidationMessage field="name" v-bind:validations="validations" />
          </div>
          <h5 class="font-weight-bold mb-0 text-gray-700" v-else>{{ customer.name }}</h5>
          <h6 class="font-weight-bold mb-0 text-gray-500 small">Atualizado em {{ customer.updatedAt | moment('DD [de] MMM/YY') }}</h6>
        </div>

        <div class="ml-auto pl-3">
          <button class="btn btn-warning" @click="isEditing = true" v-if="!isEditing">Editar</button>
        </div>
      </div>

      <div>
        <div class="row mx-0">
          <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">E-mail</h6>
              
              <div v-if="isEditing">
                <input type="text" class="form-control form-control-sm" v-model="customer.email" />
                <!-- v-on:blur="inputValidation('email')" -->
                <!-- <ValidationMessage field="email" v-bind:validations="validations" /> -->
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.email" v-else />
            </div>
          </div>

          <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Dt. Nascimento</h6>
              <div v-if="isEditing">
                <InputDatePicker v-model="customer.birthDate" v-on:blur="inputValidation('birthDate')" />
                <ValidationMessage field="birthDate" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.birthDate" @save="saveCustomer" v-else />
            </div>
          </div>

          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Gênero</h6>
              <div v-if="isEditing">
                <b-form-select
                  size="sm"
                  id="inline-form-custom-select-pref"
                  class="mb-2 mr-sm-2 mb-sm-0 col-sm-12"
                  :options="[{ text: 'Escolha...', value: null }, 'Homem', 'Mulher']"
                  v-model="customer.gender"
                />
                <ValidationMessage field="gender" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.gender" v-else />
            </div>
          </div>

          <div class="col-md-4 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Profissão atual</h6>
              <div v-if="isEditing">
                <input type="text" class="form-control form-control-sm" v-model="customer.currentProfession" v-on:blur="inputValidation('currentProfession')" />
                <ValidationMessage field="currentProfession" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.currentProfession" v-else />
            </div>
          </div>

          <div class="col-md-4 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Estado civil</h6>
              <div v-if="isEditing">
                <b-form-select
                  size="sm"
                  id="inline-form-custom-select-pref"
                  class="mb-2 mr-sm-2 mb-sm-0 col-sm-12"
                  :options="[{ text: 'Escolha...', value: null }, 'Solteiro(a)', 'Casado(a)', 'Separado(a)', 'Divorciado(a)', 'Viúvo(a)']"
                  v-model="customer.maritalStatus"
                  v-on:blur.native="inputValidation('maritalStatus')"
                />
                <ValidationMessage field="maritalStatus" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.maritalStatus" v-else />
            </div>
          </div>

          <div class="col-md-4 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Telefone Principal</h6>
              <div v-if="isEditing">
                <the-mask
                  type="text"
                  class="form-control form-control-sm"
                  id="phone"
                  value=""
                  required=""
                  v-model="customer.mainPhoneNumber"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="false"
                />
                <!-- v-on:blur.native="inputValidation('mainPhoneNumber')" -->
                <!-- <ValidationMessage field="mainPhoneNumber" v-bind:validations="validations" /> -->
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.mainPhoneNumber" v-else />
            </div>
          </div>

          <div class="col-md-4 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Telefone secundário (opcional)</h6>
              <div v-if="isEditing">
                <the-mask
                  type="text"
                  class="form-control form-control-sm"
                  id="phone"
                  value=""
                  required=""
                  v-model="customer.secondaryPhoneNumber"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="false"
                />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.secondaryPhoneNumber" v-else/>
            </div>
          </div>

          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">CEP</h6>
              <div v-if="isEditing">
                <the-mask
                  id="zipcode"
                  class="form-control form-control-sm"
                  placeholder=""
                  v-model="customer.zipcode"
                  :mask="'#####-###'"
                  :masked='false'
                />
                <!-- v-on:blur.native="inputValidation('zipcode')" -->
                <!-- <ValidationMessage field="zipcode" v-bind:validations="validations" /> -->
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.zipcode" v-else />
            </div>
          </div>

          <div class="col-md-4 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Rua</h6>
              <div v-if="isEditing">
                <input type="text" class="form-control form-control-sm" v-model="customer.address" />
                <!-- v-on:blur="inputValidation('address')"  -->
                <!-- <ValidationMessage field="address" v-bind:validations="validations" /> -->
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.address" v-else />
            </div>
          </div>

          <div class="col-md-4 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Cidade</h6>
              <div v-if="isEditing">
                <input type="text" class="form-control form-control-sm" v-model="customer.city" />
                <!-- v-on:blur="inputValidation('city')" -->
                <!-- <ValidationMessage field="city" v-bind:validations="validations" /> -->
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.city" v-else />
            </div>
          </div>

          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Estado</h6>
              <div v-if="isEditing">
                <b-form-select
                  size="sm"
                  id="inline-form-custom-select-pref"
                  class="mb-2 mr-sm-2 mb-sm-0 col-sm-12"
                  :options="stateOptions"
                  v-model="customer.state"
                />
                <!-- <ValidationMessage field="state" v-bind:validations="validations" /> -->
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.state" v-else />
            </div>
          </div>

          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small text-center" v-if="isEditing">Invalidez</h6>
              <h6 class="text-uppercase text-gray-600 small" v-else>Invalidez</h6>
              <div v-if="isEditing">
                <input type="checkbox" class="form-control" v-model="customer.disablement" />
              </div>
              <TextInfo :isLoading="isLoading" :text="(customer.disablement) ? 'SIM' : 'NÃO'" v-else />
            </div>
          </div>

          <div class="col-md-4 px-0 border-right border-bottom border-gray-200" v-if="customer.disablement">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small text-center" v-if="isEditing">Invalidez Decorrente de Acidente No Trabalho</h6>
              <h6 class="text-uppercase text-gray-600 small" v-else>Invalidez Decorrente de Acidente No Trabalho</h6>
              <div v-if="isEditing">
                <input type="checkbox" class="form-control" v-model="customer.inServiceDisablement" />
              </div>
              <TextInfo :isLoading="isLoading" :text="(customer.inServiceDisablement) ? 'SIM' : 'NÃO'" v-else />
            </div>
          </div>

          <div class="col-md-4 px-0 border-right border-bottom border-gray-200" v-if="customer.disablement">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Data da Invalidez</h6>
              <div v-if="isEditing">
                <InputDatePicker v-model="customer.disablementDate" v-on:blur="inputValidation('disablementDate')"/>
                <ValidationMessage field="disablementDate" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.disablementDate" v-else />
            </div>
          </div>

          <!-- <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Serviço Público</h6>
              <div v-if="isEditing">
                <input
                  type="number"
                  min="0"
                  class="form-control form-control-sm"
                  v-model="customer.publicServiceTime"
                  v-on:blur="inputValidation('publicServiceTime')"
                />
                <ValidationMessage field="publicServiceTime" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.publicServiceTime" v-else />
            </div>
          </div> -->

          <!-- <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Magistério</h6>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="form-control form-control-sm" v-model="customer.teachingTimeYears" />
                  <TextInfo :isLoading="isLoading" emptyText="-" :text="customer.teachingTimeYears" v-else />
                </div>
                <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="customer.teachingTimeMonths" />
                  <TextInfo :isLoading="isLoading" emptyText="-" :text="customer.teachingTimeMonths" v-else />
                </div>
                <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="customer.teachingTimeDays" />
                  <TextInfo :isLoading="isLoading" emptyText="-" :text="customer.teachingTimeDays" v-else />
                </div>
                <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
              </div>

              <ValidationMessage field="teachingTime" v-bind:validations="validations" />
            </div>
          </div> -->

          <!-- <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Carreira</h6>
              <div v-if="isEditing">
                <input
                  type="number"
                  min="0"
                  class="form-control form-control-sm"
                  v-model="customer.carreerTime"
                  v-on:blur="inputValidation('carreerTime')"
                />
                <ValidationMessage field="carreerTime" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.carreerTime" v-else />
            </div>
          </div> -->

          <!-- <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo no Cargo</h6>
              <div v-if="isEditing">
                <input type="number" min="0" class="form-control form-control-sm" v-model="customer.positionTime" v-on:blur="inputValidation('positionTime')" />
                <ValidationMessage field="positionTime" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="customer.positionTime" v-else />
            </div>
          </div> -->

          <!-- <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Contribuição</h6>

              <div class="row">
                <div class="col-6 text-gray-500 font-italic">
                  <input v-if="isEditing && !workRecords.length" type="number" min="0" class="form-control form-control-sm" v-model="customer.contributionTimeYears" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="contributionTime.years"
                    :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="customer.contributionTimeMonths" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="contributionTime.months"
                    :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing && !workRecords.length" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="customer.contributionTimeDays" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="contributionTime.days"
                    :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
              </div>
              
              <ValidationMessage field="contributionTime" v-bind:validations="validations" />
            </div>
          </div> -->
        </div>
      </div>

      <div v-if="!isEditing">
        <ListCalculations :customerId="customerIdParam" />
      </div>

      <!-- <div class="bg-gray-100 border-bottom d-flex" v-if="!isEditing">
        <router-link
          class="tab-link-item p-3"
          v-bind:to="{ name: 'rules' }"
        >
          Regras Aplicáveis
        </router-link>

        <router-link
          class="tab-link-item p-3"
          v-bind:to="{ name: 'rules-not-applicable' }"
        >
          Regras Não Aplicáveis
        </router-link>

        <router-link
          class="tab-link-item p-3"
          v-bind:to="{ name: 'work-records' }"
        >
          Registros de tempo
        </router-link>
      </div> -->

      <!-- <router-view v-if="!isEditing" /> -->
    </b-card>

    <div class="py-3 text-right" v-if="isEditing">
      <b-button :disabled="isSaving" class="btn btn-success" @click="saveCustomer()">
        <b-spinner
          v-if="isSaving"
          small
          variant="white"
        ></b-spinner>
        <i class="fa fa-pencil" v-if="!isSaving"></i> Salvar
      </b-button>
    </div>

    <button
      v-if="!isEditing"
      class="bg-transparent w-100 text-center border border-dashed p-0 mb-3 mt-4 text-gray-700"
      @click="print()"
    >
      <i class="fa fa-print" v-if="!isEditing"></i> Imprimir
    </button>

  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ListCalculations from '@/components/ListCalculations.vue';
import UserInitials from '@/components/UserInitials.vue';
import TextInfo from '@/components/TextInfo.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
import ValidationMessage from '@/components/ValidationMessage.vue';
import { updateCustomer, getCustomerById } from '@/services/customers';
import moment from 'moment';
import stateOptions from './stateOptions'

export default {
  name: 'Customer',
  components: { UserInitials, TextInfo, InputDatePicker, ValidationMessage, ListCalculations },

  props: ['customerId'],

  created() {
    this.loadCustomer();
    this.isEditing = this.$route.query?.mode === 'edit';
  },

  data() {
    const customer = {
      accountId: null,
      address: null,
      birthDate: null,
      city: null,
      createdAt: null,
      currentProfession: null,
      email: null,
      gender: null,
      id: null,
      mainPhoneNumber: null,
      maritalStatus: null,
      name: null,
      notes: null,
      publicServantCity: null,
      publicServantState: null,
      secondaryPhoneNumber: null,
      state: null,
      updatedAt: null,
      zipcode: null,
      disablement: false,
      disablementDate: null,
      inServiceDisablement: false
    };

    return {
      customer,
      stateOptions,
      workRecordMessage: 'Não é possível alterar esse campo pois o sistema está considerando os dados dos registros de trabalho.',
      isLoading: false,
      isEditing: false,
      isSaving: false,
      validations: {},
    };
  },

  computed: {
    ...mapGetters('workRecords', ['workRecords']),

    customerIdParam() {
      return this.$route.params.id;
    },

    maritalStatus() {
      return { Single: 'Solteiro', Married: 'Casado' }[this.customer?.maritalStatus];
    },

    gender() {
      return { Female: 'Feminino', Male: 'Masculino' }[this.customer?.gender];
    },

    age() {
      const { birthDate } = this.customer || {};
      if (!birthDate) { return null; }

      var birthday = +new Date(birthDate);
      return ~~((Date.now() - birthday) / (31557600000)) + ' anos';
    },

    contributionTime() {
      const { customer, workRecords } = this;

      // if (!workRecords.length) {
        return {
          years: customer.contributionTimeYears,
          months: customer.contributionTimeMonths,
          days: customer.contributionTimeDays,
        }
      // }

      // const daysTotal = workRecords.reduce((acc, workRecord) => {
      //   const { ingressDate, egressDate } = workRecord;
      //   const days = moment(egressDate).diff(moment(ingressDate), 'days');
      //   return acc + days;
      // }, 0);

      // const years = Math.floor(daysTotal / 365);
      // const months = Math.floor((daysTotal % 365) / 30);
      // const days = Math.floor((daysTotal % 365) % 30);

      // return {
      //   years,
      //   months,
      //   days,
      // };
    },
  },

  methods: {
    ...mapActions('workRecords', ['loadWorkRecords']),

    inputValidation(field) {
      let isValid = true;
      const { validations } = this;
      const isNormalField = !['contributionTime', 'teachingTime'].includes(field);
      
      if (isNormalField) {
        const value = String(this.customer[field] || '').replace('Data inválida', '');

        if (!value) {
          validations[field] = 'Campo obrigatório';
          isValid = false;
        } else {
          validations[field] = '';
        }

        if (field === 'email') {
          const emailRegex = /\S+@\S+\.\S+/;
          if (value && !emailRegex.test(value)) {
            validations.email = 'E-mail inválido';
            isValid = false;
          }
        }
      }

      this.validations = { ...validations };
      return isValid;
    },

    formValidation() {
      let isValid = true;
      const fields = [
        // 'address',
        'birthDate',
        // 'city',
        'currentProfession',
        // 'email',
        'gender',
        // 'mainPhoneNumber',
        'maritalStatus',
        'name',
        // 'state',
        'updatedAt',
        // 'zipcode',
      ]

      fields.forEach((field) => {
        if (!this.inputValidation(field)) {
          isValid = false;
        }
      });

      return isValid;
    },

    goHome() {
      this.$router.push({ name: 'home' });
    },

    async saveCustomer() {
      if (this.formValidation()) {     
        this.isLoading = true;
        this.isSaving = true;
  
        try {
          await updateCustomer(this.customer.id, this.customer);

          await this.loadCustomer();
        } catch (error) {
          this.$toast.open({
            message: 'Erro ao salvar o cliente',
            type: 'error',
            position: 'top-right'
          });
        } finally {
          this.isSaving = false;
          this.isEditing = false;
          this.isLoading = false;
        }
      }
    },

    async loadCustomer() {
      this.isLoading = true;
      const { customer } = await getCustomerById(this.$route.params.id);
      this.customer = customer;
      this.customer.birthDate = moment(this.customer.birthDate).format('DD/MM/YYYY')
      this.customer.disablementDate = moment(this.customer.disablementDate).format('DD/MM/YYYY')

      // await this.loadWorkRecords(this.$route.params.id);
      this.isLoading = false;
    },

    formatPhone(number) {
      const cleaned = String(number).replace(/\D/g, '');
      let formatted = number;

      if (cleaned && (cleaned.length === 12 || cleaned.length === 13)) {
        formatted = cleaned.replace(/^(\d\d)(\d\d)?(\d{4,5})(\d{4}).*/, '$1 ($2) $3-$4');
        formatted = `+${formatted}`;
      } else if (cleaned.length === 10 || cleaned.length === 11) {
        formatted = cleaned.replace(/^(\d\d)?(\d{4,5})(\d{4}).*/, '($1) $2-$3');
        formatted = `+55 ${formatted}`;
      }

      return formatted;
    },

    async print () {
      window.print()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/app.scss';

.customer-wrapper {
  .tab-link-item {
    color: $gray-600;
  }

  .router-link-exact-active {
    color: $info;
    font-weight: bold;
    border-bottom: 2px $info solid;
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
